
.table-fixed-header tbody {
    height: 80vh;
    overflow-y: auto;
    width: 100%;
}

.table-fixed-header thead,
.table-fixed-header tbody {
    display: block;
}

.table-fixed-header tr,
.table-fixed-header td {
    display: flex;
}


.table-fixed-header tbody td,
.table-fixed-header tbody th,

.table-fixed-header thead > tr > th {
    float: left;
    position: relative;
}

/* Tooltip */
.tooltip-inner {
    background-color: #73AD21;
    color: #FFFFFF !important;
    border: 1px solid green;
    padding: 15px;
    font-size: 20px;
}

/* Tooltip on top */
.comment-tooltip + .tooltip.top > .tooltip-arrow {
    border-top: 5px solid green;
}
/* Tooltip on bottom */
.comment-tooltip + .tooltip.bottom > .tooltip-arrow {
    border-bottom: 5px solid blue;
}
/* Tooltip on left */
.comment-tooltip + .tooltip.left > .tooltip-arrow {
    border-left: 5px solid red;
}
/* Tooltip on right */
.comment-tooltip + .tooltip.right > .tooltip-arrow {
    border-right: 5px solid black;
}